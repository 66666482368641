import clean from "./clean";

export default {
  ...clean,
  scene: {
    shader: "Med2",
    shader_speed: 0.25,
    shader_opacity: 0.25,
    shader_zoom: 0.9,
    shader_sin_cos_x: true,
    shader_sin_cos_y: true,
    shader_sin_cos_speed: 0.05,
    shader_sin_cos_space: 250,
    background: "./images/w7.jpg",
    blur: 5,
    brightness: 60,
    bgColor: "#000000",
  },
  vumeters: {
    oscilloscop: {
      show: true,
    },
  },
  composer: {
    film: {
      show: true,
      count: 1000,
      sIntensity: 1.05,
      nIntensity: 0.15,
      grayscale: false,
    },
    bloom: { show: true, strength: 0.34, threshold: 0.43, radius: 1 },
    static: { show: false, amount: 0.05, size: 5 },
    rgb: { show: true, amount: 0.004, angle: 0.65 },
    // hue: { show: true, hue: 0.77, saturation: 0 },
    film: { show: false },
  },
  texts: {
    title: {
      show: true,
      order: 5,
      text: "Analogik",
      font: "Lobster",
      x: 0,
      y: 130,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 150,
      color: "#FFBB00",
    },
    subtitle: {
      show: true,
      order: 5,
      text: "Chiptune netlabel",
      font: "Kdam Thmor Pro",
      x: -70,
      y: -19,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 39,
      color: "#000000",
    },
  },
};
