import clean from "./clean";

export default {
  ...clean,
  scene: {
    shader: "Med4",
    shader_speed: 0.025,
    shader_opacity: 0.25,
    shader_zoom: 0.2,
    shader_sin_cos_x: false,
    shader_sin_cos_y: true,
    shader_sin_cos_speed: 0.25,
    shader_sin_cos_space: 25,
    background: "./images/w2.jpg",
    blur: 2,
    brightness: 10,
  },
  composer: {
    bloom: { show: true, strength: 0.1, threshold: 0.95, radius: 0.15 },
    film: {
      show: true,
      count: 1000,
      sIntensity: .3,
      nIntensity: 0.34,
      grayscale: false,
    },
    static: { show: true, amount: 0.03, size: 5 },
  },
  texts: {
    title: {
      show: true,
      order: 5,
      text: "Analogik",
      font: "Lobster",
      x: 0,
      y: 130,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 150,
      color: "#aa66aa",
    },
    subtitle: {
      show: true,
      order: 5,
      text: "Chiptune netlabel",
      font: "Kdam Thmor Pro",
      x: -70,
      y: -19,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 39,
      color: "#000000",
    },
  },
};
