import config1 from "./variations/config1";
import config10 from "./variations/config10";
import config11 from "./variations/config11";
import config12 from "./variations/config12";
import config13 from "./variations/config13";
import config14 from "./variations/config14";
import config15 from "./variations/config15";
import config16 from "./variations/config16";
import config2 from "./variations/config2";
import config3 from "./variations/config3";
import config4 from "./variations/config4";
import config5 from "./variations/config5";
import config6 from "./variations/config6";
import config7 from "./variations/config7";
import config8 from "./variations/config8";
import config9 from "./variations/config9";

export const ConfigVariations = [
  config1,
  config1,
  config2,
  config3,
  config4,
  config5,
  config6,
  config7,
  config8,
  config9,
  config10,
  config11,
  config12,
  config13,
  config14,
  config15,
  config16,
];
