export default {
  scene: {
    background: "",
    blur: 0,
    brightness: 0,
    shader: "",
    shader_speed: 1,
    shader_opacity: 1,
    shader_zoom: 1,
    shader_sin_cos_x: false,
    shader_sin_cos_y: false,
    bgColor: "#000000",
    sparks: false,
  },

  vumeters: {
    oscilloscop: {
      order: 1,
      show: true,
      color: "#00fff0",
      bgColor: false,
      motionBlur: true,
      motionBlurLength: 0.3899999999999999,
      opacity: 1,
      width: 2048,
      height: 90,
      x: 0,
      y: 0,
      z: -217.70000000000002,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
    },
    spectrum: { show: true },
  },
  composer: {
    bloom: { show: false },
    film: { show: false },
    static: { show: false },
    rgb: { show: false },
    hue: { show: false },
  },
  texts: {
    title: {
      show: true,
      order: 5,
      text: "Analogik",
      font: "Lobster",
      x: 0,
      y: 130,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 150,
      color: "#ffffff",
    },
    subtitle: {
      show: true,
      order: 5,
      text: "Chiptune netlabel",
      font: "Kdam Thmor Pro",
      x: -70,
      y: -19,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 39,
      color: "#3e3e3e",
    },
  },
};
