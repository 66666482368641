import clean from "./clean";

export default {
  ...clean,
  scene: {
    shader: "Color",
    shader_speed: .5,
    shader_opacity: 0.33,
    shader_zoom: .5,
    shader_sin_cos_x: false,
    shader_sin_cos_y: false,
    shader_sin_cos_speed: 5,
    shader_sin_cos_space: 55,
    background: "./images/w1.jpg",
    blur: 5,
    brightness: 100,
    sparks: true,
  },
  timer: {
    opacity: 0.5,
  },
  vumeters: {
    oscilloscop: { show: true },
  },
  composer: {
    bloom: { show: false },
    film: {
      show: false,
      count: 1000,
      sIntensity: 0.11,
      nIntensity: 0.34,
      grayscale: false,
    },
    static: { show: false, amount: 0.097, size: 2.1 },
    rgb: { show: true, amount: 0.004, angle: 0.6 },
    hue: { show: false },
  },
  texts: {
    title: {
      show: true,
      order: 5,
      text: "Analogik",
      font: "Lobster",
      x: 0,
      y: 130,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 150,
      color: "#FF55CC",
    },
    subtitle: {
      show: true,
      order: 5,
      text: "Chiptune netlabel",
      font: "Kdam Thmor Pro",
      x: -70,
      y: -19,
      z: -650,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      size: 39,
      color: "#aaaaaa",
    },
  },
};
