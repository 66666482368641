import { ConfigType } from "./Components/mandafunk/types/config";

const testConfig: ConfigType = {
  scene: {
    bgColor: "#000000",
    background: "./images/w1.jpg",
    blur: 30,
    brightness: 20,
    shader: "Med1",
    shader_speed: 0.65,
    shader_opacity: 0.8,
  },
  music: "",
  timer: {
    order: 0,
    show: true,
    color: "#ffffff",
    bgColor: false,
    opacity: 1,
    width: 512,
    height: 64,
    size: 39,
    font: "Alfa Slab One",
    align: "center",
    x: 0,
    y: -270,
    z: -650,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
  },
  progressbar: {
    order: 1,
    show: true,
    color: "#ffffff",
    cursorColor: "#ffffff",
    bgColor: false,
    opacity: 1,
    width: 512,
    height: 64,
    x: 0,
    y: -230,
    z: -650,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
  },
  vumeters: {
    oscilloscop: {
      order: 1,
      show: true,
      color: "#00a4ff",
      bgColor: false,
      motionBlur: true,
      motionBlurLength: 0.5,
      opacity: 1,
      width: 2048,
      height: 90,
      x: 0,
      y: 0,
      z: -339,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
    },
    spectrum: {
      order: 2,
      show: true,
      color: "#ffffff",
      bgColor: false,
      multiColor: true,
      motionBlur: false,
      centerSpectrum: false,
      motionBlurLength: 0.15,
      opacity: 1,
      bars: 128,
      width: 496,
      height: 50,
      x: 0,
      y: -195,
      z: -650,
      zoom: 1,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
    },
  },
  composer: {
    bloom: { show: true, strength: 0.08, threshold: 0.79, radius: 1.5 },
    rgb: { show: false, amount: 0.003, angle: 0.7 },
    film: {
      show: true,
      count: 1000,
      sIntensity: 0.38,
      nIntensity: 0.44,
      grayscale: false,
    },
    static: { show: false, amount: 0.065, size: 10 },
    lens: {
      show: true,
      strength: 0.45,
      cylindricalRatio: 1,
      height: 1,
    },
  },
};

export default testConfig;
